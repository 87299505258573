<template>
  <a
    v-if="url"
    :class="classes"
    :href="url"
    :target="target"
    :style="styles"
    v-html="$t(text)"
  ></a>
</template>

<script>
import axios from 'axios';

export default {
  name: "DynamicTermsAnchor",
  data() {
    return {
      url: ""
    }
  },
  props: {
    text: {
      type: String,
      default: "Terms and conditions"
    },
    target: {
      required: false,
      type: String,
      default: "_blank"
    },
    classes: {
      required: false,
      type: String,
      default: ""
    },
    styles: {
      required: false,
      type: Object,
      default: () => { }
    }
  },
  async mounted() {
    const res = await axios.get('https://docs.google.com/spreadsheets/d/1gcXRmqMwPP9xGyYWBgyBUXN_iJXEdhAyTJV2qIRyni4/gviz/tq?tqx=out:csv&sheet=Terms%20and%20Conditions')
    const parsedData = this.parseData(res.data)
    const campaignId = this.$route.query.campaignId
    this.url = parsedData[campaignId]
  },
  methods: {
    parseData(csvContent) {
      function splitLine(line) {
        const result = [];
        let current = '';
        let inQuotes = false;

        for (let i = 0; i < line.length; i++) {
          const char = line[i];
          if (char === '"' && line[i - 1] !== '\\') {
            inQuotes = !inQuotes;
          } else if (char === ',' && !inQuotes) {
            result.push(current);
            current = '';
          } else {
            current += char;
          }
        }
        result.push(current);
        return result.map(field => field.replace(/^"(.*)"$/, '$1')); // Remove surrounding quotes
      }
      const lines = csvContent.trim().split('\n');
      const headers = [
        'campaignId',
        'termsLink'
      ];
      const result = {};

      for (let i = 1; i < lines.length; i++) {
        const obj = {};
        const currentLine = splitLine(lines[i]);
        for (let j = 0; j < headers.length; j++) {
          obj[headers[j]] = currentLine[j];
        }
        result[obj.campaignId] = obj.termsLink;
      }
      return result;
    }
  }
};
</script>

<style lang="scss" scoped>
a {
  color: inherit;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
</style>

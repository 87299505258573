import TR from './files/tr.js'
import DE from './files/de.js'
import RO from './files/ro.js'
import GR from './files/gr.js'
import HU from './files/hu.js'
import CS from './files/cs.js'
import SK from './files/sk.js'
import PL from './files/pl.js'
import FR from './files/fr.js'

export default {
  tr: TR,
  de: DE,
  ro: RO,
  gr: GR,
  hu: HU,
  cs: CS,
  sk: SK,
  pl: PL,
  fr: FR
}

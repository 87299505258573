
export default {
  isEditMode: true,
  pageProperties: {
    testing: false,
    "pages": [
      { // Login Page
        "pageName": "home",
        isPublic: true,
        pageId: 101,
        "sections": [
          { // Steps and Header
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center",
              "marginBottom": "50px",
            },
            classes: 'g-0', //no gutters
            "sectionId": "loginSection",
            "fields": [
              { // Key visual
                "fieldType": "key-visual",
                "fieldId": "key100",
                properties: {
                  grid: {
                    columns: 12,
                    marginBottom: "50px",
                  },
                  styles: {
                    "padding": '0 0 0 0;',
                  },
                  noContainer: true,
                  desktopImageUrl: "assets/images/banner/banner-gr.png",
                  tabletImageUrl: "assets/images/banner/banner-gr.png",
                  mobileImageUrl: "assets/images/banner/banner-gr-mobile.png"
                }
              },
              { // Container
                "fieldId": "jakldklf",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                  },
                  fields: [
                    {
                      "fieldType": "query-string-to-state",
                      "fieldId": "query-string-to-state",
                      "properties": {
                        grid: { columns: 12 },
                        variables: [
                          { state: 'customerToken', query: "token" },
                          { state: 'campaignId', query: "campaignId" },
                          { state: 'referenceNumber', query: "referenceNumber" }
                        ]
                      }
                    },
                    {
                      "fieldType": "check-elgibility",
                      "fieldId": "check-elgibility",
                      "properties": {
                        grid: { columns: 12 },
                        endpoint: "/checkEligibility",
                      }
                    },
                    {
                      "fieldType": "empty-bucket",
                      "fieldId": "f934if",
                      "properties": {
                        "grid": {
                          "columns": 12,
                          "justifyContent": "center",
                          "alignItems": "center",
                          "flexDirection": "column",
                        },
                        "fields": [
                          { // Heading
                            "fieldType": "paragraph",
                            "fieldId": "par1001",
                            properties: {
                              grid: {
                                columns: 8,
                                marginBottom: "30px"
                              },
                              text: "Welcome back",
                              classes: 'heading text-center'
                            }
                          },
                          { // sub Heading
                            "fieldType": "paragraph",
                            "fieldId": "par1001",
                            properties: {
                              grid: {
                                columns: 8,
                                marginBottom: "50px"
                              },
                              text: "You are a few steps away from completing your participation! Please fill in the information you will be asked for in the next steps, to receive a refund!",
                              classes: 'subHeading text-center'
                            }
                          },
                          { // Heading
                            "fieldType": "paragraph",
                            "fieldId": "par1001",
                            properties: {
                              grid: {
                                columns: 6,
                                marginBottom: "30px"
                              },
                              text: "User Login",
                              classes: 'heading text-center'
                            }
                          },
                          { // sub Heading
                            "fieldType": "paragraph",
                            "fieldId": "par1001",
                            properties: {
                              grid: {
                                columns: 6,
                                marginBottom: "50px"
                              },
                              text: "Log in as a user of epithimies.gr",
                              classes: 'subHeading text-center'
                            }
                          },
                          {
                            "fieldType": "square-image",
                            "fieldId": "5vk986f7",
                            "properties": {
                              "imageSource": "assets/images/png/epithimiesLogo.png",
                              "grid": {
                                "alignItems": "center",
                                "justifyContent": "center",
                                "columns": 6,
                                "marginBottom": "50px",
                              },
                              "className": "col-md-12 col-lg-6",
                              "styles": {
                                "maxWidth": "200px",
                                "object-fit": "contain"
                              }
                            }
                          }
                        ]
                      }
                    },
                    { // Form
                      "fieldType": "form-component",
                      "fieldId": "login",
                      "properties": {
                        "successAction": {
                          "fn": "goToPage",
                          "params": "userData"
                        },
                        "endpoint": "/janrainLogin",
                        "grid": {
                          "alignItems": "center",
                          "justifyContent": "center",
                          "columns": "12"
                        },
                        "styles": {
                          "justifyContent": "center"
                        },
                        "fields": [
                          {
                            "fieldType": "empty-bucket",
                            "fieldId": "f934if",
                            "properties": {
                              "grid": {
                                "columns": 6,
                                "justifyContent": "center",
                                "alignItems": "center",
                              },
                              "fields": [
                                {
                                  "fieldType": "empty-bucket",
                                  "fieldId": "f934if",
                                  "properties": {
                                    "grid": {
                                      "columns": 8,
                                      "justifyContent": "center",
                                      "alignItems": "center",
                                      "flexDirection": "column",
                                      "rowGap": "30px",
                                      marginBottom: "20px"
                                    },
                                    "fields": [
                                      { // First name
                                        "fieldId": "tp6zfb",
                                        "fieldType": "text-input",
                                        "properties": {
                                          "name": "email",
                                          "id": "email",
                                          "label": "Email",
                                          "validation": "required|email",
                                          "labelOnTop": true,
                                          "placeholder": "Email",
                                          "text": "",
                                          "grid": {
                                            "columns": 12
                                          }
                                        }
                                      },
                                      { // Last name
                                        "fieldId": "p55fao",
                                        "fieldType": "text-input",
                                        "properties": {
                                          "name": "password",
                                          "id": "password",
                                          "validation": "required",
                                          "labelOnTop": true,
                                          "label": "Password",
                                          "placeholder": "Password",
                                          "inputType": "password",
                                          "text": "",
                                          "grid": {
                                            "columns": 12
                                          }
                                        }
                                      }
                                    ]
                                  }
                                },
                                { // Buttons
                                  "fieldId": "fu0923r",
                                  "fieldType": "empty-bucket",
                                  "properties": {
                                    "grid": {
                                      "columns": 10,
                                      "justifyContent": "center",
                                      "alignItems": "center",
                                      "flexDirection": "column"
                                    },
                                    fields: [
                                      { // Frogto pass button
                                        "fieldType": "anchor",
                                        "fieldId": "but100",
                                        properties: {
                                          grid: {
                                            columns: 10,
                                            marginBottom: "30px"
                                          },
                                          text: "Forgot your password?",
                                          url: 'https://www.epithimies.gr/%CE%BE%CE%AD%CF%87%CE%B1%CF%83%CE%B5%CF%82-%CF%84%CE%BF%CE%BD-%CE%BA%CF%89%CE%B4%CE%B9%CE%BA%CF%8C-%CF%83%CE%BF%CF%85',
                                          target: '_blank',
                                          styles: {
                                            "font-size": "18px",
                                            "line-height": "38px",
                                            "font-weight": "700",
                                            "color": "#2F80ED !important",
                                            "text-align": "left",
                                            "text-decoration": "none",
                                          }
                                        }
                                      },
                                      { // SEND Button
                                        "fieldType": "button-component",
                                        "fieldId": "but100",
                                        properties: {
                                          grid: {
                                            columns: 6,
                                            marginBottom: "30px"
                                          },
                                          text: "LOGIN",
                                          classes: "btn-epithimies",
                                          styles: {
                                            "max-width": "300px"
                                          },
                                          requires: ['email', 'password'],
                                          "action": [
                                            {
                                              "fn": "sendForm",
                                              "params": "login"
                                            }
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                }
                              ]
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // User data
        "pageName": "userData",
        pageId: 100,
        "sections": [
          { // Steps and Header
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center",
              "marginBottom": "50px",
              "marginTop": "50px",
            },
            "classes": "container",
            "sectionId": "smsSection",
            "fields": [
              { // Container
                "fieldId": "jakldklf",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                  },
                  fields: [
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "15px"
                        },
                        text: "Confirm your details and get a refund",
                        classes: 'heading text-left'
                      }
                    },
                    { // SubHeading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "50px"
                        },
                        text: "Verify your details by entering your mobile number here. Your number will only be used once and only for the purpose of verifying your details and not for advertising purposes. After verifying your details, we will delete your phone number.",
                        classes: 'subHeading text-left'
                      }
                    },
                    { // SMS
                      "fieldType": "sms-verification",
                      "fieldId": "smsForm",
                      "properties": {
                        "grid": {
                          "alignItems": "center",
                          "justifyContent": "center",
                          "columns": 12,
                          marginBottom: "50px"
                        },
                        "endpoint": "/mobileAuth",
                        "endpoint2": "/mobileVerification",
                        "inputWidth": "720px",
                        "successAction": {
                          "fn": "goNextStep",
                          "params": "ibanSection"
                        },
                        "colorSchema": "#0099FF",
                        "btnClasses": "btn-epithimies",
                        "texts": {
                          "phoneLabel": "Cell phone number",
                          "sendBtn": "GET THE CONFIRMATION CODE (SMS)",
                          "codeLabel": "Enter the confirmation code",
                          "codeSentLabel": "We sent you a verification code via sms",
                          "notReceived": "Didnt receive it?",
                          "clickHere": "Click here",
                          "changeNumber": "Change mobile number",
                          "codeSendBtn": "SUBMISSION",
                        },
                        options: [
                          {
                            name: "Greece",
                            code: "gr",
                            phoneCode: "+30",
                          }
                        ],
                      }
                    },
                  ]
                }
              }
            ]
          },
          { // IBAN Section
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center",
              "marginBottom": "50px",
            },
            "classes": "container",
            "sectionId": "ibanSection",
            "requires": ['smsSection'],
            "fields": [
              { // IBAN Bucket
                "fieldId": "ibanSection",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "borderTop": "46px solid #0099FF",
                    "paddingTop": "50px"
                  },
                  fields: [
                    {
                      "fieldType": "empty-bucket",
                      "fieldId": "f934if",
                      "properties": {
                        "grid": {
                          "columns": 6,
                          "justifyContent": "center",
                          "alignItems": "center",
                        },
                        "fields": [
                          { // Heading
                            "fieldType": "paragraph",
                            "fieldId": "par1001",
                            properties: {
                              grid: {
                                columns: 12,
                                marginBottom: "30px"
                              },
                              text: "You can fill in your bank details after the code is confirmed",
                              classes: 'title text-left'
                            }
                          },
                          { // Heading
                            "fieldType": "paragraph",
                            "fieldId": "par1001",
                            properties: {
                              grid: {
                                columns: 12,
                                marginBottom: "15px"
                              },
                              text: "IBAN DETAILS",
                              classes: 'title text-left'
                            }
                          },
                          { // Subheading
                            "fieldType": "paragraph",
                            "fieldId": "par1001",
                            properties: {
                              grid: {
                                columns: 12,
                                marginBottom: "30px"
                              },
                              text: "Please fill in the name details below to receive the refund amount you are entitled to",
                              classes: 'subTitle text-left'
                            }
                          }
                        ]
                      }
                    },
                    { // Form
                      "fieldType": "form-component",
                      "fieldId": "lastStepForm",
                      "properties": {
                        "successAction": {
                          "fn": "goToPage",
                          "params": "thank-you"
                        },
                        "endpoint": "/submitIban",
                        "requires": ['smsForm'],
                        "grid": {
                          "alignItems": "center",
                          "justifyContent": "center",
                          "columns": "12"
                        },
                        "styles": {
                          "justifyContent": "center"
                        },
                        "fields": [
                          {
                            "fieldType": "empty-bucket",
                            "fieldId": "f934if",
                            "properties": {
                              "grid": {
                                "columns": 5,
                                "justifyContent": "center",
                                "alignItems": "center",
                                "flexDirection": "column",
                                "rowGap": "30px",
                                marginBottom: "50px"
                              },
                              "classes": "epithimies-form",
                              "fields": [
                                { // IBAN
                                  "fieldId": "h9arlr",
                                  "fieldType": "text-input",
                                  "properties": {
                                    "name": "iban",
                                    "id": "iban",
                                    "validation": "required|iban:GR",
                                    "labelOnTop": false,
                                    "label": "",
                                    "placeholder": "IBAN",
                                    "text": "",
                                    "isUpperCase": true,
                                    "disclaimer": "The field is mandatory, please fill in only the basic account number without the country code (GR)",
                                    "appendTooltip": true,
                                    "toolTip": {
                                      "text": `Ο Διεθνής Τραπεζικός Λογαριασμός (International Bank Account Number - IBAN) αποτελεί έναν τυποποιημένο αριθμό λογαριασμού δομημένο με βάση τις προδιαγραφές που έχουν εκδοθεί από την Ευρωπαϊκή Επιτροπή Τραπεζικών Προτύπων.
                                      <br/><br/>
                                      Στην Ελλάδα, ο ΙΒΑΝ αποτελείται από 27 χαρακτήρες και έχει την ακόλουθη δομή, με βάση τη σειρά που εμφανίζονται τα στοιχεία (για παράδειγμα, στο λογαριασμό GR5110010050000001234567890):
                                      <br/>
                                      <br/>Κωδικός χώρας (GR)
                                      <br/>Κωδικός επικύρωσης (51)
                                      <br/>Κωδικός τράπεζας (100)
                                      <br/>Κωδικός καταστήματος (1005)
                                      <br/>Αριθμός λογαριασμού (0000001234567890)`,
                                      'classes': 'epithimies-tooltip'
                                    },
                                    "ibanPrepend": "GR",
                                    "grid": {
                                      "columns": 12
                                    }
                                  }
                                },
                                { // First name
                                  "fieldId": "tp6zfb",
                                  "fieldType": "text-input",
                                  "properties": {
                                    "name": "firstname",
                                    "id": "firstname",
                                    "label": "",
                                    "validation": "required|ibanName",
                                    "labelOnTop": false,
                                    "placeholder": "Account holder first name",
                                    "text": "",
                                    "grid": {
                                      "columns": 12
                                    }
                                  }
                                },
                                { // Last name
                                  "fieldId": "p55fao",
                                  "fieldType": "text-input",
                                  "properties": {
                                    "name": "lastname",
                                    "id": "lastname",
                                    "validation": "required|ibanName",
                                    "labelOnTop": false,
                                    "label": "",
                                    "placeholder": "Account holder last name",
                                    "text": "",
                                    "grid": {
                                      "columns": 12
                                    }
                                  }
                                },
                                { // Subheading
                                  "fieldType": "paragraph",
                                  "fieldId": "par1001",
                                  properties: {
                                    grid: {
                                      columns: 12,
                                      marginBottom: "30px"
                                    },
                                    text: "Use only Latin characters!",
                                    classes: 'text text-left iban-disclaimer-text',
                                    styles: {
                                      "font-size": "12px",
                                      "line-height": "15px"
                                    }
                                  }
                                }
                              ]
                            }
                          }
                        ]
                      }
                    },
                    {
                      "fieldType": "empty-bucket",
                      "fieldId": "f934if",
                      "properties": {
                        "grid": {
                          "columns": 6,
                          "justifyContent": "center",
                          "alignItems": "center",
                        },
                        "fields": [
                          { // Subheading
                            "fieldType": "paragraph",
                            "fieldId": "par1001",
                            properties: {
                              grid: {
                                columns: 10,
                                marginBottom: "30px"
                              },
                              text: "ATTENTION: In case the information you filled in is not correct, the transaction will not be completed successfully.",
                              classes: 'text iban-disclaimer'
                            }
                          },
                          {
                            "fieldType": "empty-bucket",
                            "fieldId": "f934if",
                            "properties": {
                              "grid": {
                                "columns": 6,
                                "justifyContent": "center",
                                "alignItems": "center",
                              },
                              "fields": [
                                { // SEND Button
                                  "fieldType": "button-component",
                                  "fieldId": "but100",
                                  properties: {
                                    grid: {
                                      columns: 3
                                    },
                                    text: "SUBMISSION",
                                    classes: "btn-epithimies",
                                    requires: ['firstname', 'lastname', 'iban'],
                                    "action": [
                                      {
                                        "fn": "sendForm",
                                        "params": "lastStepForm"
                                      }
                                    ]
                                  }
                                }
                              ]
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Thank you
        "pageName": "thank-you",
        pageId: 34673457,
        "sections": [
          { // Heading
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "columns": 12
            },
            "classes": "container",
            "fields": [
              { // Key visual
                "fieldType": "key-visual",
                "fieldId": "key100",
                properties: {
                  grid: {
                    columns: 12,
                    marginBottom: "50px",
                  },
                  styles: {
                    "padding": '0 0 0 0;',
                  },
                  noContainer: true,
                  desktopImageUrl: "assets/images/banner/banner-gr.png",
                  tabletImageUrl: "assets/images/banner/banner-gr.png",
                  mobileImageUrl: "assets/images/banner/banner-gr-mobile.png"
                }
              },
              { // Thank you Bucket
                "fieldId": "thank100",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 10,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginBottom": "20px",
                    "marginTop": "50px"
                  },
                  "styles": {
                    "background": "#F4F4F4",
                    "border-radius": "98px",
                    "padding": "20px"
                  },
                  fields: [
                    { // Clipboard
                      "fieldType": "clipboard",
                      "fieldId": "ref123",
                      "properties": {
                        "grid": {
                          "columns": 3,
                          "padding-left": "0",
                          "padding-right": "0",
                          "marginBottom": "30px"
                        },
                        classes: 'gr-reference-number',
                        "title": "Reference number:",
                        "colorSchema": "#003DA5",
                        "disabledInput": true,
                        "variables": {
                          "referenceNumber": "referenceNumber"
                        }
                      }
                    },
                    {
                      "fieldType": "empty-bucket",
                      "fieldId": "emp1111",
                      "properties": {
                        "grid": {
                          "columns": 9,
                          "justifyContent": "center",
                          "alignItems": "center"
                        },
                        "fields": [
                          { // Heading
                            "fieldType": "heading",
                            "fieldId": "0bbo32",
                            "properties": {
                              "text": "Thank you for your participation",
                              "grid": {
                                "alignItems": "center",
                                "justifyContent": "center",
                                "columns": "12"
                              },
                              "classes": "epithimies-heading text-center"
                            }
                          },
                          { // Subheading
                            "fieldType": "paragraph",
                            "fieldId": "par1001",
                            properties: {
                              grid: {
                                columns: 10,
                                marginBottom: "30px"
                              },
                              text: "Your proof of purchase and the information you submitted have been successfully sent to us and are being reviewed.",
                              classes: 'epithimies-subHeading text-center'
                            }
                          }
                        ]
                      },
                    },
                  ]
                }
              },
              { // Thank you text
                "fieldId": "thank101",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 10,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginBottom": "50px",
                    "marginTop": "50px"
                  },
                  fields: [
                    { // Heading
                      "fieldType": "list",
                      "fieldId": "0bbo32",
                      "properties": {
                        "grid": {
                          "alignItems": "center",
                          "justifyContent": "center",
                          "columns": "12"
                        },
                        "classes": "epithimies",
                        "title": "Next steps",
                        "items": [
                          'Your phone number was entered and your bank details were sent successfully. You will soon receive a confirmation email about the progress of your participation.',
                          'The refund will be made within 5 days from the completion of the control and validation process of your participation. In case there is a problem with your membership and the support team needs to contact you, the refund will be completed within 30 days.',
                          'If a problem occurs, we will contact you again via email.',
                          'If you have questions, contact our support team support@epithimiespromo.gr',
                          'Once the refund has been successfully completed to the account you stated, you will receive a relevant confirmation email.'
                        ]
                      }
                    }
                  ]
                }
              },
              {
                "fieldType": "empty-bucket",
                "fieldId": "82358325",
                "properties": {
                  "grid": {
                    "columns": "5",
                    "alignItems": "center",
                    "justifyContent": "center"
                  },
                  "fields": [
                    {
                      "fieldType": "button-component",
                      "fieldId": "errorBackButton",
                      "properties": {
                        "text": "Back to epithimies page",
                        "grid": {
                          "columns": "12",
                          "alignItems": "center",
                          "justifyContent": "center",
                          "marginBottom": "50px"
                        },
                        classes: "btn-epithimies",
                        "disabled": false,
                        "action": {
                          "anchor": true,
                          "link": "https://www.epithimies.gr/%CE%B5%CF%80%CE%B9%CF%83%CF%84%CF%81%CE%BF%CF%86%CE%AE-%CF%87%CF%81%CE%B7%CE%BC%CE%AC%CF%84%CF%89%CE%BD"
                        }
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Thank you Reupload
        "pageName": "thank-you-reupload",
        pageId: 34673,
        // isPublic: true,
        "sections": [
          { // Heading
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "columns": 12
            },
            "classes": "container",
            "fields": [
              { // Key visual
                "fieldType": "key-visual",
                "fieldId": "key100",
                properties: {
                  grid: {
                    columns: 12,
                    marginBottom: "50px",
                  },
                  styles: {
                    "padding": '0 0 0 0;',
                  },
                  noContainer: true,
                  desktopImageUrl: "assets/images/banner/banner-gr.png",
                  tabletImageUrl: "assets/images/banner/banner-gr.png",
                  mobileImageUrl: "assets/images/banner/banner-gr-mobile.png"
                }
              },
              { // Thank you Bucket
                "fieldId": "thank103",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 10,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginBottom": "20px",
                    "marginTop": "50px"
                  },
                  fields: [
                    { // Heading
                      "fieldType": "heading",
                      "fieldId": "0bbo32",
                      "properties": {
                        "text": "Thank you for your participation",
                        "grid": {
                          "alignItems": "center",
                          "justifyContent": "center",
                          "columns": "12"
                        },
                        "classes": "epithimies-heading text-center"
                      }
                    },
                    { // Subheading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 10
                        },
                        text: "We have received the information you uploaded. Thank you very much for your participation.",
                        classes: 'epithimies-text text-center'
                      }
                    },
                  ]
                }
              },
              { // Thank you text
                "fieldId": "thank104",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 10,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginBottom": "50px",
                    "marginTop": "50px"
                  },
                  fields: [
                    { // Heading
                      "fieldType": "list",
                      "fieldId": "0bbo32",
                      "properties": {
                        "grid": {
                          "alignItems": "center",
                          "justifyContent": "center",
                          "columns": "12"
                        },
                        "classes": "epithimies",
                        "title": "Next steps",
                        "items": [
                          'After checking your new data, you will receive a confirmation email.',
                          'In the event that the information you submitted was insufficient or the photo of your purchase receipt is not legible, you will receive a message with relevant instructions via email. This process can take up to 36 hours to complete. Thank you for your understanding.',
                          'After we have successfully verified your purchase receipt and all other data, we will send you an email confirming your participation in the promotion.',
                          'If you have questions, contact our support team support@epithimiespromo.gr'
                        ]
                      }
                    }
                  ]
                }
              },
              {
                "fieldType": "empty-bucket",
                "fieldId": "82358325",
                "properties": {
                  "grid": {
                    "columns": "5",
                    "alignItems": "center",
                    "justifyContent": "center"
                  },
                  "fields": [
                    {
                      "fieldType": "button-component",
                      "fieldId": "errorBackButton",
                      "properties": {
                        "text": "Back to epithimies page",
                        "grid": {
                          "columns": "12",
                          "alignItems": "center",
                          "justifyContent": "center",
                          "marginBottom": "50px"
                        },
                        classes: "btn-epithimies",
                        "disabled": false,
                        "action": {
                          "anchor": true,
                          "link": "https://www.epithimies.gr/%CE%B5%CF%80%CE%B9%CF%83%CF%84%CF%81%CE%BF%CF%86%CE%AE-%CF%87%CF%81%CE%B7%CE%BC%CE%AC%CF%84%CF%89%CE%BD"
                        }
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // 404
        "pageName": "error-page",
        pageId: 34646,
        isPublic: true,
        "sections": [
          { // Heading
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "columns": 12
            },
            "classes": "container",
            "fields": [
              { // Container
                "fieldId": "secondaryErrorBucket",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginTop": "60px",
                    "marginBottom": "60px"
                  },
                  classes: "maxWidthOverride transparentBg errorPageMarginTop",
                  fields: [
                    {
                      "fieldType": "empty-bucket",
                      "fieldId": "82358325",
                      "properties": {
                        "grid": {
                          "columns": 12,
                          "alignItems": "center",
                          "justifyContent": "center"
                        },
                        "fields": [
                          { // Key visual
                            "fieldType": "key-visual",
                            "fieldId": "key100",
                            properties: {
                              grid: {
                                columns: 12,
                                marginBottom: "50px",
                              },
                              styles: {
                                "padding": '0 0 0 0;',
                                "max-width": '400px'
                              },
                              noContainer: true,
                              desktopImageUrl: "assets/images/png/error-epithimies.png",
                              tabletImageUrl: "assets/images/png/error-epithimies.png",
                              mobileImageUrl: "assets/images/png/error-epithimies.png"
                            }
                          },
                        ]
                      }
                    },
                    {
                      "fieldType": "empty-bucket",
                      "fieldId": "82358325",
                      "properties": {
                        "grid": {
                          "columns": 8,
                          "alignItems": "center",
                          "justifyContent": "center"
                        },
                        "fields": [
                          {
                            "fieldType": "button-component",
                            "fieldId": "errorBackButton",
                            "properties": {
                              "text": "SOMETHING WENT WRONG, START THE PROCESS ALL OVER!",
                              "grid": {
                                "columns": "12",
                                "alignItems": "center",
                                "justifyContent": "center"
                              },
                              classes: "btn-epithimies",
                              "disabled": false,
                              "action": {
                                "anchor": true,
                                "link": "/"
                              }
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Expired Page
        "pageName": "expired-page",
        pageId: 34647,
        isPublic: true,
        "sections": [
          { // Heading
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "columns": 12
            },
            "classes": "container",
            "fields": [
              { // Container
                "fieldId": "secondaryErrorBucket",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 10,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginTop": "60px",
                    "marginBottom": "60px"
                  },
                  classes: "maxWidthOverride transparentBg errorPageMarginTop",
                  fields: [
                    {
                      "fieldType": "empty-bucket",
                      "fieldId": "82358325",
                      "properties": {
                        "grid": {
                          "columns": 6,
                          "alignItems": "center",
                          "justifyContent": "center"
                        },
                        "fields": [
                          { // Heading
                            "fieldType": "paragraph",
                            "fieldId": "oopsErrorPage",
                            "properties": {
                              "text": `Unfortunately the link is no longer valid`,
                              classes: "heading text-left",
                              "grid": {
                                "columns": 12,
                                "alignItems": "center",
                                "justifyContent": "center",
                                "marginBottom": "30px",
                              }
                            }
                          },
                          { // Heading
                            "fieldType": "paragraph",
                            "fieldId": "oopsErrorPage",
                            "properties": {
                              "text": `Please contact the support team. You will find the relevant email address in the message we sent you.`,
                              classes: "subHeading text-left",
                              "grid": {
                                "alignItems": "center",
                                "justifyContent": "center",
                                "columns": 12,
                                "marginTop": "50px",
                                "marginBottom": "40px",
                              }
                            }
                          },
                        ]
                      }
                    },
                    {
                      "fieldType": "empty-bucket",
                      "fieldId": "82358325",
                      "properties": {
                        "grid": {
                          "columns": "6",
                          "alignItems": "center",
                          "justifyContent": "flex-end",
                          "marginBottom": "50px",
                        },
                        "fields": [
                          {
                            "fieldType": "square-image",
                            "fieldId": "5vk986f7",
                            "properties": {
                              "imageSource": "assets/images/png/expired-gr.png",
                              "grid": {
                                "alignItems": "center",
                                "justifyContent": "center",
                                "columns": 12,
                                "marginBottom": "50px",
                              },
                              "className": "col-md-12 col-lg-12",
                              "styles": {
                                "maxWidth": "550px",
                                "object-fit": "contain"
                              }
                            }
                          }
                        ]
                      }
                    },
                    {
                      "fieldType": "empty-bucket",
                      "fieldId": "82358325",
                      "properties": {
                        "grid": {
                          "columns": "5",
                          "alignItems": "center",
                          "justifyContent": "center"
                        },
                        "fields": [
                          {
                            "fieldType": "button-component",
                            "fieldId": "errorBackButton",
                            "properties": {
                              "text": "Back to epithimies page",
                              "grid": {
                                "columns": "12",
                                "alignItems": "center",
                                "justifyContent": "center"
                              },
                              classes: "btn-epithimies",
                              "disabled": false,
                              "action": {
                                "anchor": true,
                                "link": "https://www.epithimies.gr/%CE%B5%CF%80%CE%B9%CF%83%CF%84%CF%81%CE%BF%CF%86%CE%AE-%CF%87%CF%81%CE%B7%CE%BC%CE%AC%CF%84%CF%89%CE%BD"
                              }
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Receipt Re Upload
        "pageName": "receipt-reupload",
        isPublic: true,
        pageId: 119,
        "sections": [
          { // Section
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center",
            },
            "sectionId": "fu30zq",
            "classes": "container",
            "fields": [
              { // Query String
                "fieldType": "query-string-to-state",
                "fieldId": "uwxo2o1",
                properties: {
                  variables: [
                    {
                      query: "referenceNumber",
                      state: "referenceNumber"
                    },
                    {
                      query: "token",
                      state: "receiptToken"
                    },
                    {
                      query: "campaignId",
                      state: "campaignId"
                    },
                  ]
                }
              },
              { // 72 Hours check
                "fieldType": "checkReceiptToken",
                "fieldID": "dksnfsdkjf",
                properties: {
                  grid: { columns: 12 }
                }
              },
              { // Container
                "fieldId": "fu0923r",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginTop": "40px"
                  },
                  fields: [
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "15px"
                        },
                        text: "Upload a receipt",
                        classes: 'heading text-left'
                      }
                    },
                    { // Subheading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "25px"
                        },
                        text: "In this step, please upload the proof of purchase of your products.",
                        classes: 'subHeading text-left'
                      }
                    },
                    { // Receipt uploader
                      "fieldType": "empty-bucket",
                      "fieldId": "h453eadaf",
                      "properties": {
                        "grid": {
                          "columns": 12,
                          "alignItems": "center",
                          "justifyContent": "space-around",
                          "marginBottom": "60px",
                          "marginTop": "50px"
                        },
                        "classes": "receipt-bucket",
                        "fields": [
                          { // Recipt upload
                            "fieldType": "receipt-processor",
                            "fieldId": "recReupload",
                            "properties": {
                              "grid": {
                                "columns": "6",
                                "alignItems": "center",
                                "justifyContent": "center"
                              },
                              "successAction": {
                                "fn": "goToPage",
                                "params": "thank-you-reupload"
                              },
                              texts: {
                                choose: 'UPLOAD',
                                text1: 'Drop your photo here or select file.',
                                text2: 'Only JPG, PNG and PDF files are supported Maximum file size: 10MB',
                                wrongFormat: 'This file format is not supported. Only .jpeg, .png and .pdf files are accepted.',
                                fileSizeLimit: 'This file is too big. Only files up to 10MB can be uploaded.'
                              },
                              editTexts: {
                                crop: "Crop",
                                erase: "Erase",
                                drag: "Drag",
                                zoomIn: "Zoom in",
                                zoomOut: "Zoom out"
                              },
                              reUpload: true,
                              "colorSchema": "#0099FF",
                              "phrases": [
                                "Your receipt is now being loaded and processed.",
                                "The receipt will be processed automatically. This may take a minute. Please be patient.",
                                "If you get an error message, please get a better version of the receipt and upload it again."
                              ],
                              "cornerDetectionPhrases": [
                                "Please drag your receipt inside the gray rectangle.",
                                "You can erase private information with the Eraser tool.",
                              ],
                            }
                          },
                          { // Receipt Info
                            "fieldType": "empty-bucket",
                            "fieldId": "vdf932fd",
                            "properties": {
                              "grid": {
                                "columns": "6"
                              },
                              "classes": "addMarginLeft",
                              "fields": [
                                {
                                  "fieldType": "receipt-information",
                                  "fieldId": "5mq1p6",
                                  "properties": {
                                    "grid": {
                                      "alignItems": "center",
                                      "justifyContent": "center",
                                      "columns": 12
                                    },
                                    shouldDisplayFull: true,
                                    "texts": {
                                      "descText": `The following information must be visible:`,
                                      "descSubText": `Folded or scanned receipts or invoices will not be accepted`,
                                      "correct": "Valid",
                                      "notReadabe": "Hard to read",
                                      "folded": "Folded",
                                      "cropped": "Cut off"
                                    },
                                    "hideExamples": false,
                                    "receiptImage": "assets/images/png/receipt-epithimies.png",
                                    "steps": [
                                      { // Retailer
                                        "text": "Retailer / Shop",
                                        "label": "A"
                                      },
                                      {
                                        "text": "Date and time of purchase",
                                        "label": "B"
                                      },
                                      {
                                        "text": "Campaign product and its price",
                                        "label": "C"
                                      },
                                      {
                                        "text": "Total sum of the receipt / invoice and applied VAT",
                                        "label": "D"
                                      },
                                      {
                                        "text": "Receipt / Invoice ID (not the bank transaction ID)",
                                        "label": "E"
                                      }
                                    ]
                                  }
                                }
                              ]
                            }
                          }
                        ]
                      }
                    },
                    { // Buttons
                      "fieldId": "fu0923r",
                      "fieldType": "empty-bucket",
                      "properties": {
                        "grid": {
                          "columns": "3",
                          "marginBottom": "30px"
                        },
                        fields: [
                          { // SEND Button
                            "fieldType": "button-component",
                            "fieldId": "but100",
                            properties: {
                              grid: {
                                columns: 12
                              },
                              text: "Submit",
                              classes: "btn-epithimies",
                              "requires": ["recReupload"],
                              "action": {
                                "fn": "sendReceipt",
                                "params": ""
                              }
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Product Re Upload
        "pageName": "product-reupload",
        isPublic: true,
        pageId: 120,
        "sections": [
          { // Section
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center",
            },
            classes: 'g-0',
            "sectionId": "fu30zq",
            "fields": [
              { // Query String
                "fieldType": "query-string-to-state",
                "fieldId": "uwxo2o1",
                properties: {
                  variables: [
                    {
                      query: "referenceNumber",
                      state: "referenceNumber"
                    },
                    {
                      query: "token",
                      state: "receiptToken"
                    },
                    {
                      query: "campaignId",
                      state: "campaignId"
                    },
                  ]
                }
              },
              { // 72 Hours check
                "fieldType": "checkReceiptToken",
                "fieldID": "dksnfsdkjf",
                properties: {
                  grid: { columns: 12 }
                }
              },
              { // Container
                "fieldId": "fu0923r",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginTop": "40px"
                  },
                  "classes": "container",
                  fields: [
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 10,
                          marginBottom: "15px"
                        },
                        text: "Please upload your product photo here",
                        classes: 'heading text-left'
                      }
                    },
                    { // Subheading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 10,
                          marginBottom: "40px"
                        },
                        text: "Please upload a photo of the back of your product.",
                        classes: 'subHeading text-left'
                      }
                    },
                    { // Product Photo uploader
                      "fieldType": "empty-bucket",
                      "fieldId": "h453eadaf",
                      "properties": {
                        "grid": {
                          "columns": 6,
                          "justifyContent": "center",
                          "alignItems": "center",
                          "marginBottom": "50px"
                        },
                        classes: 'gr-product-upload',
                        "fields": [
                          {
                            "fieldType": "product-upload",
                            "fieldId": "prodPhoto",
                            "properties": {
                              texts: {
                                choose: 'Submit',
                                text1: 'Drop your photo here or select file.',
                                text2: 'Only JPG and PNG files are supported Maximum file size: 10MB',
                                wrongFormat: 'This file format is not supported. Only .jpeg and .png files are accepted.',
                                fileSizeLimit: 'This file is too big. Only files up to 10MB can be uploaded.'
                              },
                              "colorSchema": "#0099FF",
                              "grid": {
                                "columns": "8",
                                "alignItems": "center",
                                "justifyContent": "center"
                              },
                              "reUpload": true,
                              "endpoint": "/uploadProductPhoto",
                              "successAction": {
                                "fn": "goToPage",
                                "params": "thank-you-reupload"
                              }
                            }
                          }
                        ]
                      }
                    },
                    { // Product Photo uploader
                      "fieldType": "empty-bucket",
                      "fieldId": "info-bucket",
                      "properties": {
                        "grid": {
                          "columns": 6,
                          "justifyContent": "center",
                          "alignItems": "center",
                          "marginBottom": "50px"
                        },
                        classes: 'gr-product-info',
                        "fields": [
                          { // heading
                            "fieldType": "paragraph",
                            "fieldId": "par1001",
                            properties: {
                              grid: {
                                columns: 8,
                                marginBottom: "25px"
                              },
                              text: "product-upload-heading",
                              classes: 'text-left'
                            }
                          },
                          {
                            "fieldType": "square-image",
                            "fieldId": "5vk986f7",
                            "properties": {
                              "imageSource": "assets/images/png/product-upload-gr.png",
                              "grid": {
                                "alignItems": "flex-start",
                                "justifyContent": "flex-start",
                                "columns": 8,
                              }
                            }
                          }
                        ]
                      }
                    },
                    { // Buttons
                      "fieldId": "fu0923r",
                      "fieldType": "empty-bucket",
                      "properties": {
                        "grid": {
                          "columns": "3",
                          justifyContent: "center",
                          "marginBottom": "30px",
                          "marginTop": "30px"
                        },
                        fields: [
                          { // SEND Button
                            "fieldType": "button-component",
                            "fieldId": "but100",
                            properties: {
                              grid: {
                                columns: 3
                              },
                              text: "Submit",
                              classes: "btn-epithimies",
                              "requires": ["prodPhoto"],
                              "action": {
                                "fn": "sendProductPhotos",
                                "params": "prodPhoto"
                              }
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Iban reuploader
        "pageName": "iban-reupload",
        isPublic: true,
        pageId: 123,
        "requires": [
          1
        ],
        "sections": [
          { // Section
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "columns": 12,
              "marginTop": "40px"
            },
            "fields": [
              { // Query String
                "fieldType": "query-string-to-state",
                "fieldId": "uwxo2o1",
                properties: {
                  variables: [
                    {
                      query: "referenceNumber",
                      state: "referenceNumber"
                    },
                    {
                      query: "campaignId",
                      state: "campaignId"
                    },
                    {
                      query: "customerToken",
                      state: "customerToken"
                    },
                  ]
                }
              },
              {
                "fieldType": "iban-reupload",
                "fieldId": "ij3411"
              },
              { // Container
                "fieldId": "fu0923r",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center"
                  },
                  "classes": "container",
                  fields: [
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "15px"
                        },
                        text: "Fill in your bank details",
                        classes: 'heading text-left'
                      }
                    },
                    { // Subheading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "30px"
                        },
                        text: "Please fill in the details of the bank account to which the refund amount will be transferred.",
                        classes: 'subHeading text-left'
                      }
                    },
                    {
                      "fieldType": "empty-bucket",
                      "fieldId": "f934if",
                      "properties": {
                        "grid": {
                          "columns": 12,
                          "justifyContent": "center",
                          "alignItems": "center",
                        },
                        "fields": [
                          { // Form
                            "fieldType": "form-component",
                            "fieldId": "bankRe",
                            "properties": {
                              "successAction": {
                                "fn": "goToPage",
                                "params": "thank-you-reupload"
                              },
                              "endpoint": "/updateIBAN",
                              "grid": {
                                "alignItems": "center",
                                "justifyContent": "center",
                                "columns": "12",
                                marginBottom: "20px"
                              },
                              "styles": {
                                "justifyContent": "center"
                              },
                              "fields": [
                                {
                                  "fieldType": "empty-bucket",
                                  "fieldId": "f934if",
                                  "properties": {
                                    "grid": {
                                      "columns": 5,
                                      "justifyContent": "center",
                                      "alignItems": "center",
                                      "flexDirection": "column",
                                      "rowGap": "30px",
                                      marginBottom: "50px"
                                    },
                                    "classes": "epithimies-form",
                                    "fields": [
                                      { // IBAN
                                        "fieldId": "h9arlr",
                                        "fieldType": "text-input",
                                        "properties": {
                                          "name": "iban",
                                          "id": "iban",
                                          "validation": "required|iban:GR",
                                          "labelOnTop": false,
                                          "label": "",
                                          "placeholder": "IBAN",
                                          "text": "",
                                          "isUpperCase": true,
                                          "disclaimer": "The field is mandatory, please fill in only the basic account number without the country code (GR)",
                                          "appendTooltip": true,
                                          "toolTip": {
                                            "text": `Ο Διεθνής Τραπεζικός Λογαριασμός (International Bank Account Number - IBAN) αποτελεί έναν τυποποιημένο αριθμό λογαριασμού δομημένο με βάση τις προδιαγραφές που έχουν εκδοθεί από την Ευρωπαϊκή Επιτροπή Τραπεζικών Προτύπων.
                                            <br/><br/>
                                            Στην Ελλάδα, ο ΙΒΑΝ αποτελείται από 27 χαρακτήρες και έχει την ακόλουθη δομή, με βάση τη σειρά που εμφανίζονται τα στοιχεία (για παράδειγμα, στο λογαριασμό GR5110010050000001234567890):
                                            <br/>
                                            <br/>Κωδικός χώρας (GR)
                                            <br/>Κωδικός επικύρωσης (51)
                                            <br/>Κωδικός τράπεζας (100)
                                            <br/>Κωδικός καταστήματος (1005)
                                            <br/>Αριθμός λογαριασμού (0000001234567890)`,
                                            'classes': 'epithimies-tooltip'
                                          },
                                          "ibanPrepend": "GR",
                                          "grid": {
                                            "columns": 12
                                          }
                                        }
                                      },
                                      { // First name
                                        "fieldId": "tp6zfb",
                                        "fieldType": "text-input",
                                        "properties": {
                                          "name": "firstname",
                                          "id": "firstname",
                                          "label": "",
                                          "validation": "required|ibanName",
                                          "labelOnTop": false,
                                          "placeholder": "Account holder first name",
                                          "text": "",
                                          "grid": {
                                            "columns": 12
                                          }
                                        }
                                      },
                                      { // Last name
                                        "fieldId": "p55fao",
                                        "fieldType": "text-input",
                                        "properties": {
                                          "name": "lastname",
                                          "id": "lastname",
                                          "validation": "required|ibanName",
                                          "labelOnTop": false,
                                          "label": "",
                                          "placeholder": "Account holder last name",
                                          "text": "",
                                          "grid": {
                                            "columns": 12
                                          }
                                        }
                                      },
                                      { // Subheading
                                        "fieldType": "paragraph",
                                        "fieldId": "par1001",
                                        properties: {
                                          grid: {
                                            columns: 12,
                                            marginBottom: "30px"
                                          },
                                          text: "Use only Latin characters!",
                                          classes: 'text text-left',
                                          styles: {
                                            "font-size": "12px",
                                            "line-height": "15px"
                                          }
                                        }
                                      }
                                    ]
                                  }
                                }
                              ]
                            }
                          }
                        ]
                      }
                    },
                    { // Buttons
                      "fieldId": "fu0923r",
                      "fieldType": "empty-bucket",
                      "properties": {
                        "grid": {
                          "columns": 4,
                          "marginBottom": "30px"
                        },
                        classes: "btn-bucket",
                        fields: [
                          { // SEND Button
                            "fieldType": "button-component",
                            "fieldId": "but100",
                            properties: {
                              grid: {
                                columns: 3
                              },
                              text: "SUBMISSION",
                              classes: "btn-epithimies",
                              requires: ['firstname', 'lastname', 'iban'],
                              "action": {
                                "fn": "sendForm",
                                "params": "bankRe"
                              }
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Iban thank you page
        "pageName": "thank-you-iban",
        pageId: 124,
        "sections": [
          {
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "columns": 12,
              "marginTop": "50px"
            },
            "fields": [
              { // Key visual
                "fieldType": "key-visual",
                "fieldId": "key100",
                properties: {
                  grid: {
                    columns: 12,
                    marginBottom: "50px",
                  },
                  styles: {
                    "padding": '0 0 0 0;',
                  },
                  noContainer: true,
                  desktopImageUrl: "assets/images/banner/banner-gr.png",
                  tabletImageUrl: "assets/images/banner/banner-gr.png",
                  mobileImageUrl: "assets/images/banner/banner-gr-mobile.png"
                }
              },
              { // Container
                "fieldId": "fu0923r",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginBottom": "60px"
                  },
                  classes: "container",
                  fields: [
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "50px"
                        },
                        text: "Thank you",
                        classes: 'heading text-center'
                      }
                    },
                    { // Subheading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 10,
                          marginBottom: "50px"
                        },
                        text: "Thank you for resubmitting your bank details. So there should now be a successful transfer.",
                        classes: 'text text-center'
                      }
                    },
                    { // Back to home Button
                      "fieldType": "button-component",
                      "fieldId": "but100",
                      properties: {
                        grid: {
                          columns: 8
                        },
                        text: "Back to campaign page",
                        classes: "btn-epithimies",
                        styles: {
                          "max-width": "600px"
                        },
                        "action": {
                          "anchor": true,
                          "link": "/"
                        }
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Payout
        pageName: "payout",
        isPublic: true,
        pageId: 125,
        requires: [
          1
        ],
        "sections": [
          { // Section
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "columns": 12,
              "marginTop": "40px"
            },
            "fields": [
              { // Key visual
                "fieldType": "key-visual",
                "fieldId": "key100",
                properties: {
                  grid: {
                    columns: 12,
                    marginBottom: "50px",
                  },
                  styles: {
                    "padding": '0 0 0 0;',
                  },
                  noContainer: true,
                  desktopImageUrl: "assets/images/banner/banner-gr-payout.png",
                  tabletImageUrl: "assets/images/banner/banner-gr-payout.png",
                  mobileImageUrl: "assets/images/banner/banner-gr-payout-mobile.png"
                }
              },
              { // Query String
                "fieldType": "query-string-to-state",
                "fieldId": "uwxo2o1",
                properties: {
                  variables: [
                    {
                      query: "campaignId",
                      state: "campaignId"
                    },
                    {
                      query: "customerToken",
                      state: "customerToken"
                    },
                    {
                      query: "redirectionUrl",
                      state: "redirectionUrl"
                    }
                  ]
                }
              },
              { // Container
                "fieldId": "fu0923r",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginTop": "50px",
                  },
                  "classes": "container",
                  fields: [
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "15px"
                        },
                        text: "Request your payout",
                        classes: 'heading text-center',
                        styles: {
                          'color': '#003DA5'
                        }
                      }
                    },
                    { // Subheading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 6,
                          marginBottom: "50px"
                        },
                        text: "Please enter the information of your account to which the refund amount will be transferred.",
                        classes: 'subHeading text-center'
                      }
                    },
                    { // Form
                      "fieldType": "form-component",
                      "fieldId": "p4y0utF0RM",
                      "properties": {
                        "successAction": {
                          "fn": "popupAndRedirect",
                          "params": {
                            message: "Εχουμε λάβει τις πληροφορίες που συμπλήρωσες.",
                            type: "success",
                            query: "redirectionUrl",
                            delay: 5000
                          }
                        },
                        "endpoint": "/submitIBAN",
                        "keepInState": ["redirectionUrl"],
                        "grid": {
                          "alignItems": "center",
                          "justifyContent": "center",
                          "columns": "12",
                          marginBottom: "20px"
                        },
                        "styles": {
                          "justifyContent": "center"
                        },
                        "fields": [
                          {
                            "fieldType": "empty-bucket",
                            "fieldId": "f934if",
                            "properties": {
                              "grid": {
                                "columns": 6,
                                "justifyContent": "center",
                                "alignItems": "center",
                                "flexDirection": "column",
                                "rowGap": "30px",
                                marginBottom: "50px"
                              },
                              "styles": {
                                "border": "1px solid #5D5FEF",
                                "box-shadow": "0px 0px 16px rgba(185, 185, 255, 0.65)",
                                "border-radius": "8px",
                                "padding": "40px"
                              },
                              "fields": [
                                { // IBAN
                                  "fieldId": "h9arlr",
                                  "fieldType": "text-input",
                                  "properties": {
                                    "name": "iban",
                                    "id": "iban",
                                    "validation": "required|iban:GR",
                                    "labelOnTop": true,
                                    "label": "IBAN",
                                    "placeholder": "Enter your IBAN number",
                                    "text": "",
                                    "ibanPrepend": "GR",
                                    "isUpperCase": true,
                                    "grid": {
                                      "columns": 12
                                    }
                                  }
                                },
                                { // Subheading
                                  "fieldType": "paragraph",
                                  "fieldId": "par1001",
                                  properties: {
                                    grid: {
                                      columns: 12,
                                      marginTop: "-10px"
                                    },
                                    text: "Το πεδίο είναι υποχρεωτικό, παρακαλώ συμπληρώστε μόνο τoν βασικό αριθμό λογαριασμού χωρίς τον κωδικό χώρας (GR)",
                                    classes: 'subHeading text-left',
                                    styles: {
                                      'color': '#b1b1b1'
                                    }
                                  }
                                },
                                { // First name
                                  "fieldId": "tp6zfb",
                                  "fieldType": "text-input",
                                  "properties": {
                                    "name": "firstname",
                                    "id": "firstname",
                                    "label": "Account holder first name",
                                    "validation": "required|ibanName",
                                    "labelOnTop": true,
                                    "placeholder": "Account holder first name",
                                    "text": "",
                                    "grid": {
                                      "columns": 12
                                    }
                                  }
                                },
                                { // Last name
                                  "fieldId": "p55fao",
                                  "fieldType": "text-input",
                                  "properties": {
                                    "name": "lastname",
                                    "id": "lastname",
                                    "validation": "required|ibanName",
                                    "labelOnTop": true,
                                    "label": "Account holder last name",
                                    "placeholder": "Account holder last name",
                                    "text": "",
                                    "grid": {
                                      "columns": 12
                                    }
                                  }
                                }
                              ]
                            }
                          },
                        ]
                      }
                    },
                    {
                      "fieldType": "empty-bucket",
                      "fieldId": "82358325",
                      properties: {
                        grid: {
                          columns: 10,
                          alignItems: "center",
                          justifyContent: "center",
                          marginBottom: "40px"
                        },
                        fields: [
                          { // Subheading
                            "fieldType": "paragraph",
                            "fieldId": "par1001",
                            properties: {
                              grid: {
                                columns: 8,
                              },
                              text: "ΠΡΟΣΟΧΗ:",
                              classes: 'subHeading text-left',
                              styles: {
                                'color': '#c20052',
                                'text-shadow': '0px 4px 4px rgba(0, 0, 0, 0.25)',
                                'font-weight': '600',
                                'font-size': '16px',
                              }
                            }
                          },
                          { // Subheading
                            "fieldType": "paragraph",
                            "fieldId": "par1001",
                            properties: {
                              grid: {
                                columns: 8,
                                marginBottom: "30px"
                              },
                              text: "Σε περίπτωση που τα στοιχεία που συμπλήρωσες δεν είναι ορθά, η συναλλαγή δεν θα ολοκληρωθεί με επιτυχία.",
                              classes: 'subHeading text-left'
                            }
                          },
                        ]
                      }
                    },
                    { // Buttons
                      "fieldId": "fu0923r",
                      "fieldType": "empty-bucket",
                      "properties": {
                        "grid": {
                          "columns": 4,
                          "marginBottom": "50px"
                        },
                        classes: "btn-bucket",
                        fields: [
                          { // SEND Button
                            "fieldType": "button-component",
                            "fieldId": "but100",
                            properties: {
                              grid: {
                                columns: 3
                              },
                              text: "SEND",
                              classes: "btn-primary",
                              requires: ['firstname', 'lastname', 'iban'],
                              "action": {
                                "fn": "sendForm",
                                "params": "p4y0utF0RM"
                              }
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      }
    ],
    "header": {
      "fieldType": "navbar",
      "fieldId": "cbx34t",
      "properties": {
        "grid": {
          "columns": 12
        },
        "favicon": {
          "imageSource": "assets/images/svg/pglogo.svg"
        },
        mainWrapperClass: 'gr-navbar',
        "backgroundColor": "#fff",
        "color": "#FFE082",
        "target":"_blank",
        "logo": {
          "imageSource": "assets/images/png/epithimiesLogo.png",
          "width": 75,
          "url":"https://www.epithimies.gr/",
          "alternateText": "Brand Logo",
          "paddingTop": "30px"
        },
        "fields": [
        ],
        "meta": [
          { "name": "title", "content": "Επιστροφή χρημάτων Always Platinum" },
          {
            "name": "description",
            "content": ``
          },
          {
            "name": "keywords",
            "content": `PG`
          }
        ]
      }
    },
    "footer": {
    },
  },
  componentStatus: {},
  modal: {
    type: 'error',
    message: 'An error occured.',
    isActive: false
  },
  customerToken: '',
  receiptToken: '',
  campaignId: '',
  referenceNumber: '',
  formData: new FormData(),
  currentSection: 'loginSection',
  country: 'GR'
}
